<template>
  <div>
    <template v-if="regularShelfDisplayList&&regularShelfDisplayList.length>0">
      <div class="form-title">常规货架陈列</div>
      <div class="image-container">
        <template v-for="(item, index) in regularShelfDisplayList">
          <el-image class="image" :src="item" :preview-src-list="regularShelfDisplayList" :key="index"></el-image>
        </template>
      </div>
    </template>
    <template v-if="materialDisplayList&&materialDisplayList.length>0">
      <div class="form-title">物料陈列</div>
      <div class="image-container">
        <template v-for="(item, index) in materialDisplayList">
          <el-image class="image" :src="item" :preview-src-list="materialDisplayList" :key="index"></el-image>
        </template>
      </div>
    </template>
    <template v-if="priceList&&priceList.length>0">
      <div class="form-title">价格签</div>
      <div class="image-container">
        <template v-for="(item, index) in priceList">
          <el-image class="image" :src="item" :preview-src-list="priceList" :key="index"></el-image>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'images',
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          const regularShelfDisplayList = []; // 常规货架陈列
          const materialDisplayList = []; // 物料陈列
          const priceList = []; // 价格签
          if (val.regularShelfDisplayList && val.regularShelfDisplayList.length > 0) {
            val.regularShelfDisplayList.forEach((v) => {
              regularShelfDisplayList.push(v.urlPathPrefix + v.urlPath);
            });
          }
          if (val.materialDisplayList && val.materialDisplayList.length > 0) {
            val.materialDisplayList.forEach((v) => {
              materialDisplayList.push(v.urlPathPrefix + v.urlPath);
            });
          } if (val.priceList && val.priceList.length > 0) {
            val.priceList.forEach((v) => {
              priceList.push(v.urlPathPrefix + v.urlPath);
            });
          }
          this.regularShelfDisplayList = regularShelfDisplayList;
          this.materialDisplayList = materialDisplayList;
          this.priceList = priceList;
        }
      },
    },
  },
  data() {
    return {
      regularShelfDisplayList: [], // 常规货架检查列表
      materialDisplayList: [], // 物料陈列列表
      priceList: [], // 价格签列表
    };
  },
};
</script>
<style lang="less" scoped>
  .form-title{
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 18px;
  }
  .image-container{
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    .image{
      width: 120px;
      height: 120px;
      margin: 0 20px 20px 20px;
    }
  }
</style>
