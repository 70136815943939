var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.regularShelfDisplayList && _vm.regularShelfDisplayList.length > 0
        ? [
            _c("div", { staticClass: "form-title" }, [_vm._v("常规货架陈列")]),
            _c(
              "div",
              { staticClass: "image-container" },
              [
                _vm._l(_vm.regularShelfDisplayList, function (item, index) {
                  return [
                    _c("el-image", {
                      key: index,
                      staticClass: "image",
                      attrs: {
                        src: item,
                        "preview-src-list": _vm.regularShelfDisplayList,
                      },
                    }),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm.materialDisplayList && _vm.materialDisplayList.length > 0
        ? [
            _c("div", { staticClass: "form-title" }, [_vm._v("物料陈列")]),
            _c(
              "div",
              { staticClass: "image-container" },
              [
                _vm._l(_vm.materialDisplayList, function (item, index) {
                  return [
                    _c("el-image", {
                      key: index,
                      staticClass: "image",
                      attrs: {
                        src: item,
                        "preview-src-list": _vm.materialDisplayList,
                      },
                    }),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm.priceList && _vm.priceList.length > 0
        ? [
            _c("div", { staticClass: "form-title" }, [_vm._v("价格签")]),
            _c(
              "div",
              { staticClass: "image-container" },
              [
                _vm._l(_vm.priceList, function (item, index) {
                  return [
                    _c("el-image", {
                      key: index,
                      staticClass: "image",
                      attrs: { src: item, "preview-src-list": _vm.priceList },
                    }),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }