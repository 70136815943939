<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import Images from './components/images.vue';

formCreate.component('Images', Images);

export default {
  extends: Form,
  components: {
    // Modal,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    this.buttons.submit = false;
    const rule = await this.getFormRule('sfaCenter_visitManage_storeInspection_detailForm');
    rule.forEach((item) => {
      const v = item;
      v.props = {
        ...v.props,
        disabled: true,
        readonly: true,
      };
    });
    // this.rule = rule;
    // this.reload(this.rule);
    if (this.formConfig.type === 'view') {
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      // const oldRequestUrl = '/sfa/sfavisitstepstorecheck/queryStoreCheckReportById'
      const requestUrl = '/sfa/sfavisitstepstorecheck/findStoreCheckReportDetailEs';
      request.get(requestUrl, { id: this.formConfig.id }).then((res) => {
        if (res.success) {
          const { result } = res;
          this.setValue(result);
          console.log(result);

          this.setValue({
            images: result,
          });
          this.row = res.result;
        }
      });
    },
  },
};
</script>
